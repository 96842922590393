import React, { useState } from 'react'
import starIcon from '../../assets/images/star-icon.png'
import contact from '../../assets/images/contact.png'

const functionURL = "https://mahogany-rhinoceros-1026.twil.io/send-email-test"

const ContactForm = () => {
    const [submitting, setSubmitting] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');

    const sendMessage = async () => {
        
        setSubmitting(true);
        const response = await fetch(functionURL, {
            method: "post",
            headers: {
              "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            body: new URLSearchParams({ fromEmail: email, subject: 'Hello from FlutterPals', body: `phoneNumber: ${phoneNumber}, name: ${name}, Message: ${message}` }).toString(),
          })
          if (response.status === 200) {
           alert("Email has been sent. Thank You!");

           setTimeout(() => {
            setName('');
            setEmail('');
            setMessage('');
            setPhoneNumber('');
           }, 0)
          
          } else {
            const json = await response.json()
            console.log('json error', json);
            alert("Sending email failed. Please try again.");
          }

           setSubmitting(false);
    }

    return (
        <section className="contact-area pb-100">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="contact" /> 
                        Get in Touch
                    </span>
                    <h2>Ready to Get Started?</h2>
                    <p>Your email address will not be published. Required fields are marked *</p>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="contact-image">
                            <img src={contact} alt="contact" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="contact-form">
                            <form id="contactForm">
                                <div className="row">
                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="text" name="name" className="form-control" onChange={(e) => setName(e.target.value)} required placeholder="Your name" />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="email" name="email" className="form-control" onChange={(e) => setEmail(e.target.value)} required placeholder="Your email address" />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <input type="text" name="phone_number" className="form-control" onChange={(e) => setPhoneNumber(e.target.value)} required placeholder="Your phone number" />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <textarea name="message" className="form-control" onChange={(e) => setMessage(e.target.value)} cols="30" rows="6" required placeholder="Write your message..." />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <button disabled={submitting} type="submit" className="default-btn" onClick={(e) => {
                                            e.preventDefault();
                                            sendMessage();
                                        }}>
                                            <i className="flaticon-tick"></i> 
                                            Send Message <span></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactForm